// Sidebar.js
import React from 'react';
import '../components/Sidebar.css';

const Sidebar = ({ setores = [], handleFiltroBusca, handleSetorClick }) => {
  return (
    <div className="sidebar-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Buscar..."
          onChange={(e) => handleFiltroBusca(e.target.value)}
        />
      </div>
      <div className="sector-list">
        {setores.length > 0 ? (
          setores.map((setor) => (
            <div 
              key={setor} 
              className="sector-item" 
              onClick={() => handleSetorClick(setor)}
            >
              {setor}
            </div>
          ))
        ) : (
          <div className="no-results">Nenhum setor encontrado</div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
