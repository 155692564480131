import './Dominio.css';

import {
  FaRocket,
  FaEnvelope,
  FaLink,
  FaCamera,
  FaPhotoVideo,
  FaLifeRing,
  FaPoll,
  FaCalendarAlt,
  FaMap,
  FaBloggerB,
  FaBriefcase,
} from 'react-icons/fa';

function Dominio() {

  return (

  <div>
    <h1>Tire o máximo proveito do seu domínio</h1>
      <div className="maximize">
      <p>
        Além da oportunidade de empregar o domínio na criação de um site profissional, há uma variedade de aplicações que merecem ser exploradas. Algumas delas incluem:
      </p>

        <p><FaEnvelope className="icon"/><strong> E-mail Personalizado</strong>:</p>
        <p>Configure contas de e-mail exclusivas associadas ao seu domínio.</p>
        <p><FaLink className="icon"/><strong> Redirecionamento para Redes Sociais</strong>:</p>
        <p> Encaminhe visitantes diretamente para suas páginas em redes sociais, como WhatsApp, Facebook e Instagram.</p>
        <p><FaCamera className="icon"/><strong> Galeria de Fotos</strong>:</p><p> Destaque seus produtos e/ou serviços por meio de uma galeria visualmente atrativa.</p>
        <p><FaPhotoVideo className="icon"/><strong> Hospedagem Personalizada de Conteúdo Multimídia</strong>:</p>
        <p> Explore a possibilidade de compartilhar áudio (por exemplo, podcasts) ou vídeos diretamente no seu domínio.</p>
        <p><FaBriefcase className="icon"/><strong> Portfólio de Projetos</strong>:</p>
        <p> Exponha seus projetos e trabalhos anteriores de maneira organizada e profissional.</p>
        <p><FaBloggerB className="icon"/><strong> Blog ou Fórum Temático</strong>:</p>
        <p> Construa uma plataforma dedicada a discutir temas específicos, seja por meio de um blog informativo ou um fórum interativo.</p>
        <p><FaMap className="icon"/><strong> Mapa Interativo</strong>:</p>
        <p> Crie uma experiência envolvente com um mapa online que destaque locais de interesse.</p>
        <p> <FaCalendarAlt className="icon"/><strong> Página de Reserva ou Agendamento</strong>:</p>
        <p> Facilite reservas ou agendamentos online para serviços ou eventos.</p>
        <p><FaPoll className="icon"/><strong> Plataforma de Enquetes/Pesquisas Interativas</strong>:</p>
        <p> Saiba a opinião do seu público por meio de pesquisas interativas ou enquetes online.</p>
        <p><FaLifeRing className="icon"/><strong> Central de Suporte</strong>:</p>
        <p> Forneça assistência e informações importantes através de uma central de suporte online.</p>
        <p> Além dessas opções, há muitas outras a explorar. Conte conosco para ajudá-lo no que for preciso.</p>
        </div>
    </div>

  );
}

export default Dominio;
