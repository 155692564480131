import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaWhatsapp } from 'react-icons/fa'; // Importa os ícones do Facebook e WhatsApp
import './Header.css';

function Header() {
  return (
    <header>
      <div className="header-container">
        <a href="https://www.vedo.com.br/" target="_self" rel="noopener noreferrer" className="logo-link">
          <img src="./logov.png" alt="Logo" className="logo" />

        </a>

        <nav className="navbar">
          <div className="social-icons">
            <a href="https://www.facebook.com/dominios.vedo" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaFacebook className="social-icon" />
            </a>
            <a href="https://wa.me/551150502769" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaWhatsapp className="social-icon" />
            </a>
          </div>
          <Link to="/">Home</Link>
          <Link to="/QuemSomos">Quem Somos?</Link>
          <Link to="/Contato/QueroVender" className="highlighted-link">Quero Vender</Link>
          {/*<Link to="/Painel">Painel</Link>*/}

        </nav>
      </div>
    </header>
  );
}

export default Header;
