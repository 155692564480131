import React from 'react';
import './Faq.css';
import ScrollToTop from '../components/ScrollToTop';

const FaqItem = ({ question, answer, isOpen }) => {
  return (
    <details open={isOpen} className="faq-item">
      <summary className="faq-question">
        <h1>{question}</h1>
      </summary>
      <p className="faq-answer">{answer}</p>
    </details>
  );
};

const Faq = () => {
  return (
    <div className="faq-container">
      <div className="faq">
        <FaqItem
          question="Quem somos?"
          answer="Somos uma Vitrine Eletrônica de Domínios dedicada a conectar compradores e vendedores de domínios premium. Nossa missão é facilitar a transação de nomes de domínio exclusivos e de alta qualidade, fornecendo uma plataforma confiável e conveniente para empresas e empreendedores. Como parte do Grupo AF3 Soluções, combinamos nossa expertise com a força e os recursos de um grupo de referência no mercado."
          isOpen={true} // Define o primeiro item como aberto inicialmente
        />
        <FaqItem
          question="O que são domínios premium?"
          answer="Domínios premium são nomes de domínio altamente desejáveis e valiosos, que se destacam por sua qualidade, relevância e potencial de impacto. Eles são escolhidos estrategicamente por suas características únicas e podem incluir palavras-chave relevantes para setores específicos ou serem nomes distintos e memoráveis."
          isOpen={false} // Outros itens fechados inicialmente
        />
        <FaqItem
          question="Como comprar um domínio?"
          answer="Comprar um domínio na Vedo é simples. Navegue em nossa plataforma de domínios disponíveis e encontre aquele que atenda às suas necessidades. Entre em contato com o vendedor diretamente para iniciar a negociação e acertar os detalhes da transação."
          isOpen={false} // Outros itens fechados inicialmente
        />
        <FaqItem
          question="Como vender meu domínio?"
          answer="Se você possui um domínio premium para vender, pode listar seu domínio na Vedo para alcançar potenciais compradores. Crie uma conta em nossa plataforma, forneça as informações sobre o domínio e aguarde o contato de interessados. A partir daí, você poderá negociar diretamente com os potenciais compradores."
          isOpen={false} // Outros itens fechados inicialmente
        />
        <ScrollToTop/>

      </div>
    </div>
  );
};

export default Faq;
