import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsAppIcon.css';


const phoneNumber = '551150502769'; //

function WhatsAppIcon() {
  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-icon"
    >
      <FaWhatsapp size={32} color="#25D366" />
    </a>
  );
}

export default WhatsAppIcon;
