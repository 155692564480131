import React, { useState, useEffect } from 'react';
import './Contato.css';
import { useParams } from 'react-router-dom';
import emailjs from 'emailjs-com';
import WhatsAppIcon from './WhatsAppIcon';
import { firestore } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';

function Contato() {
  const { dominioNome } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [queroVenderEnabled, setQueroVenderEnabled] = useState(false);
  const [textareaPlaceholder, setTextareaPlaceholder] = useState('Digite sua mensagem aqui');
  const [dominioValor, setDominioValor] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  useEffect(() => {
    if (dominioNome === 'QueroVender') {
      setQueroVenderEnabled(true);
      setTextareaPlaceholder('Liste o nome e o preço dos domínios e nós retornaremos o contato o quanto antes.');
      setFormData(prevState => ({
        ...prevState,
        message: ''
      }));
      setDominioValor('');
      setLoadingMessage('');
    } else {
      setQueroVenderEnabled(false);
      setTextareaPlaceholder('Digite sua mensagem aqui');
      setFormData(prevState => ({
        ...prevState,
        message: dominioNome && dominioNome !== 'Contato' ? `Olá, tenho interesse no domínio ${dominioNome}.` : ''
      }));

      // Obtenha o valor do domínio do Firestore
      const fetchDomainValue = async () => {
        try {
          console.log('Buscando valor para domínio:', dominioNome); // Log para depuração

          // Consulta ao Firestore para buscar o documento com o campo nome igual ao dominioNome e status igual a 'Consultar'
          const dominiosRef = collection(firestore, 'dominios');
          const q = query(dominiosRef, where('nome', '==', dominioNome), where('status', '==', 'Consultar'));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            const data = doc.data();
            console.log('Dados do documento:', data); // Log para depuração

            // Formata o valor do domínio como moeda BRL
            const formattedValue = new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            }).format(data.valor);

            setDominioValor(formattedValue || 'Preço não disponível');
            setLoadingMessage(''); // Limpa a mensagem de carregamento após obter o valor
          } else {
            console.log('Domínio indisponível para venda'); // Log adicional para depuração
            setDominioValor('domínio indisponível para venda');
            setLoadingMessage(''); // Limpa a mensagem de carregamento após obter o resultado
          }
        } catch (error) {
          console.error('Erro ao buscar valor do domínio:', error);
          setDominioValor('Erro ao buscar valor');
          setLoadingMessage(''); // Limpa a mensagem de carregamento após erro
        }
      };

      if (dominioNome) {
        fetchDomainValue();
      } else {
        setDominioValor('Preço não disponível');
        setLoadingMessage('');
      }
    }
  }, [dominioNome]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSending(true); // Desativa o botão enquanto o e-mail está sendo enviado

    emailjs.sendForm('enviaremailvedo', 'template_venaxcj', e.target, 'CtqNjPWscFl08NDjp')
      .then(
        (result) => {
          console.log('SUCCESS:', result.text);
          setSuccessMessage('E-mail enviado com sucesso!');
          setErrorMessage('');
          setFormData({
            name: '',
            email: '',
            message: ''
          });
        },
        (error) => {
          console.log('FAILED:', error.text);
          setErrorMessage('Erro ao enviar e-mail. Por favor, tente novamente.');
          setSuccessMessage('');
        }
      )
      .finally(() => {
        setSending(false); // Reativa o botão após o envio
      });
  };

  const handleQueroVenderClick = () => {
    setFormData({
      ...formData,
      message: ''
    });
  };

  return (
    <div className="contato-container">
      <div className="contato-header">
        <p>ONLINE</p>
        <WhatsAppIcon />
      </div>
      <div className="contato-header">
        <h1>{dominioNome.toUpperCase()} {dominioValor}</h1> {/* Exibe o valor do domínio */}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            name="name"
            placeholder="Nome"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            name="email"
            placeholder="E-mail"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <textarea
            id="message"
            name="message"
            rows="4"
            cols="50"
            value={formData.message}
            onChange={handleChange}
            placeholder={textareaPlaceholder}
            required
          ></textarea>
        </div>
        <div className="button-container">
          <button type="submit" disabled={sending}>Enviar</button>
        </div>
      </form>
      {successMessage && <p className="success">{successMessage}</p>}
      {errorMessage && <p className="error">{errorMessage}</p>}
    </div>
  );
}

export default Contato;
