import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { shuffle } from 'lodash';
import { firestore } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import Sidebar from '../components/Sidebar';
import '../components/App.css';

const App = () => {
  const { dominioNome } = useParams();
  const [dominios, setDominios] = useState([]);
  const [firestoreData, setFirestoreData] = useState([]);
  const [setores, setSetores] = useState([]);
  const [filtroBusca, setFiltroBusca] = useState('');
  const [setorSelecionado, setSetorSelecionado] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(1000); // Define quantos itens por página exibir

  // Função para buscar dados do Firestore
  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'dominios'));
      const data = [];
      const setoresSet = new Set();
      querySnapshot.forEach((doc) => {
        const dominio = { ...doc.data(), id: doc.id };
        data.push(dominio);
        setoresSet.add(dominio.setor);
      });
      setFirestoreData(data);
      setDominios(shuffle(data));
      setSetores(Array.from(setoresSet).sort());
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Manipulador para filtro de busca
  const handleFiltroBusca = (value) => {
    setFiltroBusca(value.toLowerCase());
    setCurrentPage(1); // Resetar a página ao mudar o filtro
  };

  // Manipulador para seleção de setor
  const handleSetorClick = (setor) => {
    setSetorSelecionado(setor);
    setFiltroBusca(setor.toLowerCase());
    setCurrentPage(1); // Resetar a página ao mudar de setor
  };

  // Filtragem de domínios
  const filteredDominios = dominios.filter((dominio) => {
    const buscaSetor = filtroBusca ? dominio.setor.toLowerCase().includes(filtroBusca) : true;
    const buscaNome = filtroBusca ? dominio.nome.toLowerCase().includes(filtroBusca) : true;
    return buscaSetor || buscaNome;
  });

  const setoresFiltrados = setorSelecionado
    ? filteredDominios.filter((dominio) => dominio.setor === setorSelecionado)
    : filteredDominios;

  // Lógica de paginação
  const indexOfLastItem = currentPage * itemsPerPage; // Último índice do item atual
  const indexOfFirstItem = indexOfLastItem - itemsPerPage; // Primeiro índice do item atual
  const currentItems = setoresFiltrados.slice(indexOfFirstItem, indexOfLastItem); // Itens da página atual

  const totalPages = Math.ceil(setoresFiltrados.length / itemsPerPage); // Total de páginas
  return (
    <div className="app-container">
      <div className="app-container">
       
        {/* Sidebar */}
        <Sidebar setores={setores} handleFiltroBusca={handleFiltroBusca} handleSetorClick={handleSetorClick} />
        
        <div className="card-container">
          {currentItems.length === 0 ? (
            <p>Nenhum domínio encontrado.</p>
          ) : (
            currentItems.map((dominio) => (
              <div className="card" key={dominio.id}>
              <h3>{dominio.nome.toLowerCase()}</h3>
<p className="setor">{dominio.setor}</p>
                <div className={`button button-${dominio.status.toLowerCase()}`}>
                  <p>{dominio.status}</p>
                </div>
              </div>
            ))
          )}
        </div>
  
        {/* Exibir paginação apenas se houver mais de uma página */}
        {totalPages > 1 && (
          <div className="pagination-container">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Anterior
            </button>
            <span>{`Página ${currentPage} de ${totalPages}`}</span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Próxima
            </button>
          </div>
        )}
      </div>
    </div>
  );
  
  
};

export default App;