import React from 'react';
import './CadastroDominio.css';

const CadastroDominio = ({ novoDominio, handleChange, handleSubmit, registroEditando }) => (
  <div className="cadastro-dominio">
    <h2>{registroEditando ? 'Editar Domínio' : 'Cadastrar Novo Domínio'}</h2>
    <form onSubmit={handleSubmit}>
      {['nome', 'contato', 'titular', 'setor', 'valor'].map((field, idx) => (
        <div className="form-group" key={idx}>
          <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
          <input
            type={field === 'valor' ? 'number' : 'text'}
            id={field}
            name={field}
            value={novoDominio[field]}
            onChange={handleChange}
            required
          />
        </div>
      ))}
      <button type="submit">{registroEditando ? 'Atualizar' : 'Cadastrar'}</button>
    </form>
  </div>
);

export default CadastroDominio;
