import { initializeApp, getApp, getApps } from 'firebase/app';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

// Configuração do Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA5XOGYD22S7YjZbRcI2eqKIGGQHvLEbWA",
  authDomain: "vitrine-1f820.firebaseapp.com",
  databaseURL: "https://vitrine-1f820-default-rtdb.firebaseio.com",
  projectId: "vitrine-1f820",
  storageBucket: "vitrine-1f820.appspot.com",
  messagingSenderId: "681931464985",
  appId: "1:681931464985:web:845dac350da8766098e588",
};

// Inicialize o app Firebase se ainda não estiver inicializado
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const db = getFirestore(app);

const WhoisChecker = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const checkAllDomains = async () => {
      setLoading(true);
      setError('');

      try {
        // Consulta todos os registros na coleção 'dominios'
        const snapshot = await getDocs(collection(db, 'dominios'));
        const domains = snapshot.docs.map(doc => doc.data());
        console.log('Domínios consultados:', domains);

        // Função para adicionar intervalo entre as consultas
        const fetchWithDelay = async (domain, delay) => {
          return new Promise((resolve) => {
            setTimeout(async () => {
              try {
                const { nome, contato } = domain;
                // Substitua com o endpoint correto da sua API ou serviço proxy
                const response = await axios.get(`/whois/${nome}`);
                console.log(`Dados recebidos para ${nome}:`, response.data);
                const owner = parseWhoisOwner(response.data);

                resolve({
                  domain: nome,
                  isOwnerMatch: owner === contato,
                  owner,
                  expectedOwner: contato
                });
              } catch (err) {
                console.error(`Erro ao consultar o domínio ${domain.nome}:`, err);
                resolve({
                  domain: domain.nome,
                  isOwnerMatch: false,
                  owner: 'Erro na consulta',
                  expectedOwner: domain.contato
                });
              }
            }, delay);
          });
        };

        // Processa os domínios com intervalo
        const results = [];
        for (let i = 0; i < domains.length; i++) {
          const domain = domains[i];
          const result = await fetchWithDelay(domain, i * 60000); // Intervalo de 1 minuto entre as consultas
          results.push(result);
        }

        setResults(results);
      } catch (err) {
        setError('Erro ao consultar o Firestore.');
        console.error('Erro ao consultar o Firestore:', err);
      } finally {
        setLoading(false);
      }
    };

    checkAllDomains();
  }, []);

  // Função fictícia para extrair o proprietário do HTML retornado
  const parseWhoisOwner = (htmlData) => {
    // Ajuste a lógica para extrair o proprietário com base na estrutura real do HTML
    console.log('HTML recebido:', htmlData); // Adicione este log para inspeção
    return 'Alfra119'; // Exemplo estático, substitua com a lógica real
  };

  return (
    <div>
      <h1>Whois Checker</h1>
      {loading && <p>Carregando...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <table>
        <thead>
          <tr>
            <th>Domínio</th>
            <th>Proprietário Esperado</th>
            <th>Proprietário Encontrado</th>
            <th>Corresponde?</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result, index) => (
            <tr key={index}>
              <td>{result.domain}</td>
              <td>{result.expectedOwner}</td>
              <td>{result.owner}</td>
              <td>{result.isOwnerMatch ? 'Sim' : 'Não'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WhoisChecker;
