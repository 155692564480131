import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <img
        src="https://www.paypalobjects.com/webstatic/pt_PT/mktg/logo-center/bdg_powered_by_pp_pt.png"
        alt="Check out with PayPal"
        className="footer-logo"
      />
      <p className="footer-text">
        Empresa do Grupo <strong>AF3 Soluções</strong> - Compra e Venda de Domínios de Internet Genéricos | Precisa de ajuda? - Contatos: dominios@vedo.com.br - 11 5050-2769
      </p>
      <Link to="/politica-de-privacidade" className="footer-link">
        Política de Privacidade
      </Link>
    </footer>
  );
}

export default Footer;
