import { initializeApp, getApps, getApp } from 'firebase/app';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore'; 
import { getAuth } from 'firebase/auth';
import { getAnalytics} from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyA5XOGYD22S7YjZbRcI2eqKIGGQHvLEbWA",
  authDomain: "vitrine-1f820.firebaseapp.com",
  databaseURL: "https://vitrine-1f820-default-rtdb.firebaseio.com",
  projectId: "vitrine-1f820",
  storageBucket: "vitrine-1f820.appspot.com",
  messagingSenderId: "681931464985",
  appId: "1:681931464985:web:845dac350da8766098e588",
  measurementId: "G-WPR64SKNE5"
};

// Inicializa o Firebase apenas se ainda não estiver inicializado
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const firestore = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

// Função para registrar uma visita
async function logvisita() {
  try {
    await addDoc(collection(firestore, "visitas"), {
      timestamp: serverTimestamp(),
      page: window.location.pathname,
    });
    console.log("Visita registrada com sucesso!");
  } catch (error) {
    console.error("Erro ao registrar visita:", error);
  }
}

// Exporta os módulos necessários
export { firestore, auth, logvisita };