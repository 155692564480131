import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaEnvelope, FaLink, FaCamera, FaPhotoVideo, FaBriefcase, FaBloggerB, FaMap, FaCalendarAlt, FaPoll, FaLifeRing } from "react-icons/fa";
import { PiBirdLight } from "react-icons/pi";
import { GiCheckMark } from "react-icons/gi";
import Login from './Login';


import Header from './Header';
import Footer from './Footer';
import './Detalhes.css'; // Importe seu arquivo de estilos CSS

const Detalhes = () => {
  const { dominioNome } = useParams();
  const [activeIndex, setActiveIndex] = useState(null);

  // Verifica se o domínioNome é igual a "Contato"
  const isContato = dominioNome === "Contato";

  const beneficios = [
    { titulo: "E-mail Personalizado", descricao: "Configure contas de e-mail exclusivas associadas ao seu domínio." },
    { titulo: "Redirecionamento para Redes Sociais", descricao: "Encaminhe visitantes diretamente para suas páginas em redes sociais, como WhatsApp, Facebook e Instagram." },
    { titulo: "Galeria de Fotos", descricao: "Destaque seus produtos e/ou serviços por meio de uma galeria visualmente atrativa." },
    { titulo: "Hospedagem Personalizada de Conteúdo Multimídia", descricao: "Explore a possibilidade de compartilhar áudio (por exemplo, podcasts) ou vídeos diretamente no seu domínio." },
    { titulo: "Portfólio de Projetos", descricao: "Exponha seus projetos e trabalhos anteriores de maneira organizada e profissional." },
    { titulo: "Blog ou Fórum Temático", descricao: "Construa uma plataforma dedicada a discutir temas específicos, seja por meio de um blog informativo ou um fórum interativo." },
    { titulo: "Mapa Interativo", descricao: "Crie uma experiência envolvente com um mapa online que destaque locais de interesse." },
    { titulo: "Página de Reserva ou Agendamento", descricao: "Facilite reservas ou agendamentos online para serviços ou eventos." },
    { titulo: "Plataforma de Enquetes/Pesquisas Interativas", descricao: "Saiba a opinião do seu público por meio de pesquisas interativas ou enquetes online." },
    { titulo: "Central de Suporte", descricao: "Forneça assistência e informações importantes através de uma central de suporte online." },
  ];

  const handleToggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

    if (dominioNome === "Login") {
    return <Login />;
  }

  return (
    <div>
      <Header />

      <div className="cardd">
        {isContato ? ( // Se for "Contato", renderiza o formulário de contato
          <div>
            <h5 className="title">Fale conosco</h5>
            <form>
              <div className="form-group">
                <input type="text" placeholder="Nome" required />
              </div>
              <div className="form-group">
                <input type="email" placeholder="E-mail" required />
              </div>
              <div className="form-group">
                <textarea id="mensagem" rows="4" cols="50" placeholder="Mensagem" required></textarea>
              </div>
              <div className="button-container">
                <button type="submit">Enviar</button>
              </div>
            </form>
          </div>
        ) : ( // Caso contrário, renderiza as informações sobre o domínio
          <div>
            <h1 className="title">
              <PiBirdLight size="120px" /> {dominioNome}
            </h1>
            <h2><strong>Este endereço está disponível para venda</strong></h2>

            <div>
              <div className="maximize">
                <p>
                  Além da oportunidade de empregar o domínio na criação de um site profissional, há uma variedade de aplicações que merecem ser exploradas. Algumas delas incluem:
                </p>
                {/* Utilização de ícones para cada item de benefício */}
                <div className="beneficios">
                  {beneficios.map((item, index) => (
                    <div key={index} onClick={() => handleToggleAccordion(index)} className={`beneficio ${activeIndex === index ? 'active' : ''}`}>
                      <GiCheckMark className="icon" />
                      <div className="beneficio-content">
                        <p><strong>{item.titulo}</strong></p>
                        <p> {item.descricao}</p>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Call-to-action mais destacado */}
                <div className="call-to-action">
                  <p>Além dessas opções, há muitas outras a explorar. Conte conosco para ajudá-lo no que for preciso.</p>
                  <Link to={`/Contato/${dominioNome}`}>
                    <button className="botao-contato">Entrar em contato</button>
                    <Link to={`/`}>
                    <button className="botao-contato">Explorar +</button>
                  </Link>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Detalhes;
