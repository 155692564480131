                                                                            import React, { useState } from 'react';
                                                                            import firebase from 'firebase/compat/app';
                                                                            import 'firebase/compat/firestore';
                                                                            import 'firebase/compat/auth';
                                                                            import { Navigate } from 'react-router-dom';
                                                                            import './Login.css'; // Importe o arquivo CSS
                                                                            import Painel from './Painel';
                                                                            import { AiOutlineLogin } from "react-icons/ai";


                                                                            const firebaseConfig = {
                                                                              // suas configurações do Firebase aqui
                                                                            };

                                                                            // Inicialize o Firebase se ainda não estiver inicializado
                                                                            if (!firebase.apps.length) {
                                                                              firebase.initializeApp(firebaseConfig);
                                                                            }

                                                                            const Login = () => {
                                                                              const [loginMessage, setLoginMessage] = useState(''); // Estado para armazenar a mensagem de login
                                                                              const [loggedIn, setLoggedIn] = useState(false); // Estado para controlar se o usuário está logado

                                                                              const handleGoogleLogin = async () => {
                                                                                try {
                                                                                  const provider = new firebase.auth.GoogleAuthProvider();
                                                                                  const result = await firebase.auth().signInWithPopup(provider);

                                                                                  // Verifique se o e-mail do usuário está autorizado
                                                                                  const userEmail = result.user.email;
                                                                                  const authorizedEmails = ['umdevbr@gmail.com', 'fndo.xyz@gmail.com','fcbertoo@gmail.com' ]; // Lista de e-mails autorizados
                                                                                  if (authorizedEmails.includes(userEmail)) {
                                                                                    // Se o e-mail do usuário estiver autorizado, marque o usuário como logado
                                                                                    setLoggedIn(true);
                                                                                    setLoginMessage('Login bem-sucedido!');
                                                                                  } else {
                                                                                    // Se o e-mail do usuário não estiver autorizado, faça logout e exiba uma mensagem de erro
                                                                                    await firebase.auth().signOut();
                                                                                    setLoginMessage('Acesso não autorizado.');
                                                                                  }
                                                                                } catch (error) {
                                                                                  console.error('Error signing in with Google:', error.message);
                                                                                  // Se houver um erro no login, atualize a mensagem de login com o erro
                                                                                  setLoginMessage(`Erro ao fazer login: ${error.message}`);
                                                                                }
                                                                              };

                                                                              // Se o usuário estiver logado, redirecione para a rota de manutenção
                                                                              if (loggedIn) {
                                                                                return <Painel />;
                                                                              }

                                                                              return (
                                                                                <div className="login-container">
                                                                                    <h4 className="painel-title">PAINEL DE CONTROLE</h4>
                                                                                    <AiOutlineLogin className="login-icon" onClick={handleGoogleLogin}/>
                                                                                    <button className="login-button" onClick={handleGoogleLogin}>Entrar</button>
                                                                                    {loginMessage && <p className="login-message">{loginMessage}</p>} {/* Exibir a mensagem de login se existir */}
                                                                                </div>
                                                                              );
                                                                            };
                                                                            export default Login;