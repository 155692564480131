import React, { useState } from 'react';
import './MercadoFinanceiro.css'; // Importe o arquivo CSS para estilização (se necessário)

const DuvidasFrequentes = () => {
  // Estado para controlar qual pergunta está aberta
  const [perguntaAberta, setPerguntaAberta] = useState(null);

  // Estado para controlar qual vídeo deve ser exibido para cada pergunta
  const [videoExibido, setVideoExibido] = useState({
    investidores: null,
    gestores: null,
    distribuidores: null,
  });

  // Função para alternar a pergunta aberta
  const alternarPergunta = (indice) => {
    setPerguntaAberta(indice === perguntaAberta ? null : indice);
  };

  // Função para abrir o vídeo correspondente
  const alternarVideo = (tipo, videoId) => {
    setVideoExibido({ ...videoExibido, [tipo]: videoId });
  };

  return (
    <div id="duvidasfrequentes">
      <h3>Dúvidas frequentes</h3>
      <p>Perguntas e respostas organizadas de acordo com o seu interesse</p>
      <div className="trilha-publico">
        <div className="card">
          {videoExibido.investidores ? (
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${videoExibido.investidores}?autoplay=1`}
              title="Vídeo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <img src="/canaldeduvidas.png" alt="Imagem 1" />
          )}
          <h2>Investidores</h2>
          <div className="perguntas">
            <a
              href="#videogestoradm"
              className="pergunta link-card"
              onClick={() => alternarVideo('investidores', 'XpcJJNIa6YA')}
            >
              Todos podem investir nos FIDCs?
            </a>
          </div>
        </div>

        <div className="card">
          {videoExibido.gestores ? (
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${videoExibido.gestores}?autoplay=1`}
              title="Vídeo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <img src="/canaldeduvidas.png" alt="Imagem 1" />
          )}
          <h2>Gestores e Administradores</h2>
          <div className="perguntas">
            <a
              href="#videogestoradm"
              className="pergunta link-card"
              onClick={() => alternarVideo('gestores', 'nl-xB9-fXnw')}
            >
              O que são: “fundo casca”, classes e subclasses?
            </a>
          </div>
        </div>

        <div className="card">
          {videoExibido.distribuidores ? (
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${videoExibido.distribuidores}?autoplay=1`}
              title="Vídeo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <img src="/canaldeduvidas.png" alt="Imagem 1" />
          )}
          <h2>Distribuidores</h2>
          <div className="perguntas">
            <a
              href="#videodistribuidores"
              className="pergunta link-card"
              onClick={() => alternarVideo('distribuidores', 'g7l755eNcas?si=-98RYYxMjz3tGc30')}
            >
              Qual o Futuro dos Fundos de Investimento com a RCVM175
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuvidasFrequentes;
