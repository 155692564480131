import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import Contato from './components/Contato';
import QuemSomos from './components/QuemSomos';
import Detalhes from './components/Detalhes';
import Dominio from './components/Dominios';
import Painel from './components/Painel';
import Mercado from './components/MercadoFinanceiro';
import { logvisita } from "./firebaseConfig"; // Corrigido o caminho da importação

const App = () => {
  useEffect(() => {
    logvisita();
  }, []);

  return (

    <Router>
      <Routes>
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/QuemSomos" element={<Layout><QuemSomos /></Layout>} />
        <Route path="/Contato/:dominioNome" element={<Layout><Contato /></Layout>} />

        <Route path="/Dominio" element={<Layout><Painel /></Layout>} />
//        <Route path="/Painel" element={<Layout><Painel /></Layout>} />
        <Route path="/MercadoFinanceiro" element={<Layout><Mercado /></Layout>} />

        //<Route path="/:dominioNome" element={<Detalhes><dominioNome /></Detalhes>} />
        <Route path="*" element={<Layout><Home /></Layout>} />
        //<Route path="/detalhes/:dominioNome" component={Detalhes} />
      </Routes>
    </Router>

  );
};

export default App;
